<template>
  <div class="paper-card">
    <card-frame>
      <div slot="frontName">
        Лицевая сторона
      </div>
      <div slot="frontContent">
        <div class="card__inner">
          <div class="row">
            <div class="col-6">
              <div class="card__left">
                <div class="card__head">
                  <div class="card__logo" data-role="card_logo">
                    <img
                      src="@/assets/images/business-card/paper/logo__en.png"
                      alt="rostelecom"
                      v-if="lang === langs.EN.text"
                    >
                    <img
                      src="@/assets/images/business-card/paper/logo__ru.png"
                      alt="ростелеком"
                      v-else
                    >
                  </div>
                </div>
                <div class="card__employee">
                  <div class="card__lastname" data-role="card_lastname">
                    {{ lastName }}
                  </div>
                  <span
                    data-role="card_name"
                    class="card-name"
                  >
                    {{ firstName }}&nbsp;
                  </span>
                  <span data-role="card_patronymic" class="card-patronymic">{{
                    middleName
                  }}</span>
                </div>
                <div
                  class="card__position"
                  data-role="card_position"
                >
                  {{ position }}
                </div>
                <div data-role="card__branch" class="card__branch">
                  {{ department }}
                </div>
                <div
                  class="card__department"
                  data-role="card__department"
                >
                  {{ organization }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card__right">
                <div class="card__body">
                  <p>
                    <span data-role="card_phone">{{ mobilePhone }}</span>
                  </p>
                  <p>
                    <span data-role="card_phone">{{ workPhone }}</span>
                  </p>
                  <p>
                    <span data-role="card_email">{{ email }}</span>
                  </p>
                  <p>
                    <span data-role="card_address">{{ address }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="backName">
        Оборотная сторона
      </div>
      <div slot="backContent">
        <div class="card__item">
          <div class="card__img" data-role="card_img">
            <img
              :key="bg"
              :src="bgFileName"
              alt="ростелеком"
            >
          </div>
        </div>
      </div>
    </card-frame>

    <div class="visit-card-layout-cnt">
      <canvas
        id="visit-card-layout"
        width="460"
        height="300"
        v-show="false"
      />
    </div>
  </div>
</template>

<script>
import { BG_TYPES } from '@/constants/businessCard';
import CardFrame from './card-frame.vue';
import CardMixin from './card-mixin.vue';

export default {
  name: 'PaperCard',
  props: {
    lang: {
      type: String,
      default: undefined,
    },
    department: {
      type: String,
      default: undefined,
    },
    organization: {
      type: String,
      default: undefined,
    },
    company: {
      type: String,
      default: undefined,
    },
    lastName: {
      type: String,
      default: undefined,
    },
    firstName: {
      type: String,
      default: undefined,
    },
    middleName: {
      type: String,
      default: undefined,
    },
    position: {
      type: String,
      default: undefined,
    },
    mobilePhone: {
      type: String,
      default: undefined,
    },
    workPhone: {
      type: String,
      default: undefined,
    },
    email: {
      type: String,
      default: undefined,
    },
    address: {
      type: String,
      default: undefined,
    },
    bg: {
      type: String,
      default: undefined,
    },
  },
  components: {
    CardFrame,
  },
  mixins: [CardMixin],
  computed: {
    bgFileName() {
      const fileName = BG_TYPES.paper[this.bg]
        ? BG_TYPES.paper[this.bg].fileName
        : BG_TYPES.paper.blue.fileName;

      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/images/business-card/paper/${fileName}.jpg`);
    },
  },
  methods: {
    draw() {
      const canvas = document.getElementById('visit-card-layout');
      if (!canvas) return false;

      const context = canvas.getContext('2d');
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = 'black';
      const logo = new Image(129, 57);
      // const background = new Image();

      const draw = async () => {
        const info = [];
        if (this.workPhone && this.workPhone.trim().length > 0) {
          info.push(this.workPhone);
        }
        if (this.mobilePhone && this.mobilePhone.trim().length > 0) {
          info.push(this.mobilePhone);
        }
        if (this.email && this.email.trim().length > 0) {
          info.push(this.email);
        }

        // context.drawImage(background, 0, 0);
        context.drawImage(logo, 20, 20, 194, 68);
        // Позиция печати левой части визитки
        const position = {
          XPosition: 30,
          YPosition: 120,
        };

        this.fillMultiLineText(
          context,
          position,
          this.lastName,
          "600 20px/23px 'Basis Grotesque Pro'",
          20,
          20,
          2,
          220,
        );
        this.fillMultiLineText(
          context,
          position,
          `${this.firstName} ${this.middleName}`,
          "500 20px/23px 'Basis Grotesque Pro'",
          20,
          35,
          2,
          240,
        );
        this.fillMultiLineText(
          context,
          position,
          this.position,
          "500 15px/15px 'Basis Grotesque Pro'",
          15,
          20,
          1.8,
        );
        this.fillMultiLineText(
          context,
          position,
          this.department,
          "300 13px/15px 'Basis Grotesque Pro'",
          15,
          17,
          1.8,
        );
        this.fillMultiLineText(
          context,
          position,
          this.organization,
          "300 13px/15px 'Basis Grotesque Pro'",
          15,
          0,
          1.8,
        );

        // Позиция печати правой части визитки
        position.XPosition = 270;
        position.YPosition = 180;
        context.font = "300 14px/18px 'Basis Grotesque Pro'";

        for (let i = 0; i < info.length; i += 1) {
          // TODO: change for forEach
          const infoItem = info[i];
          this.fillMultiLineText(
            context,
            position,
            infoItem,
            "300 13px/15px 'Basis Grotesque Pro'",
            15,
            0,
            1.8,
          );
          position.YPosition += 17;
        }

        this.fillMultiLineText(
          context,
          position,
          this.address,
          "300 14px/18px Basis Grotesque Pro'",
          12,
          18,
          4,
        );

        const front = this.dataURItoBlob(canvas.toDataURL('image/jpeg'));
        front.lastModifiedDate = new Date();
        front.name = 'layout.jpg';
        front.isBlob = true;

        const back = await this.getBlobByUrl(this.bgFileName, 'layout2.jpg');

        this.imgs = [front, back];
      };

      logo.addEventListener('load', draw);

      // eslint-disable-next-line global-require
      const enLogo = require('@/assets/images/business-card/paper/logo__en.png');
      // eslint-disable-next-line global-require
      const ruLogo = require('@/assets/images/business-card/paper/logo__ru.png');

      logo.src = this.lang === this.langs.EN.text ? enLogo : ruLogo;

      return true;
    },
  },
  mounted() {
    this.draw();
  },
  updated() {
    this.draw();
  },
};
</script>

<style lang="scss" scoped>
.card__inner {
  height: 300px;
  min-height: 299px;
  padding: 15px 20px 20px 30px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ddd;

  @include for-size(phone-portrait-down) {
    height: 209px;
    min-height: 209px;
    padding: 7px 10px 10px 15px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.card__logo {
  padding: 0 0 15px;
  line-height: 0;
  img {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-left: -10px;
  }
  @include for-size(phone-portrait-down) {
    padding-bottom: 7px;
  }
}
.card__employee {
  padding: 0 0 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  color: #000;

  @include for-size(phone-portrait-down) {
    font-size: 13px;
    line-height: 16px;
  }
}
.card-name,
.card-patronymic {
  display: inline-block;
  width: auto;
}
.card__position {
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  color: #000;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.card__branch {
  margin-top: 6px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #000;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.card__department {
  margin-top: 3px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #000;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.card__body {
  float: right;
  width: 100%;
  padding-top: 162px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  text-align: left;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
    padding-top: 120px;

    p {
      line-height: 14px;
    }
  }
}
.card__img {
  overflow: hidden;
  img {
    height: 300px;
    display: block;
    @include for-size(phone-portrait-down) {
      height: 209px;
    }
  }
}

.visit-card-layout-cnt {
  width: 100%;
  overflow: hidden;
}
</style>
