<template>
  <div class="plastic-card">
    <card-frame>
      <div slot="frontName">
        Лицевая сторона
      </div>
      <div slot="frontContent">
        <div class="plastic-card__front">
          <div class="card__inner">
            <div class="row">
              <div class="col-6">
                <div class="card__left">
                  <div class="card__head">
                    <div class="card__logo" data-role="card_logo">
                      <img
                        src="@/assets/images/business-card/plastic/plastic-logo__ru.svg"
                        alt="rostelecom"
                      >
                    </div>
                  </div>
                  <div class="card__employee">
                    <div class="card__lastname" data-role="card_lastname">
                      {{ lastName }}
                    </div>
                    <span
                      data-role="card_name"
                      class="card-name"
                    >
                      {{ firstName }}&nbsp;
                    </span>
                    <span data-role="card_patronymic" class="card-patronymic">{{
                      middleName
                    }}</span>
                  </div>
                  <div
                    class="card__position"
                    data-role="card_position"
                  >
                    {{ position }}
                  </div>
                  <div data-role="card__branch" class="card__branch">
                    {{ department }}
                  </div>
                  <div
                    class="card__department"
                    data-role="card__department"
                  >
                    {{ organization }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="plastic-card__contact-code">
            <img
              v-if="contactCode"
              :src="contactCode"
              alt="contactCode"
            >
          </div>
        </div>
      </div>
      <div slot="backName">
        Оборотная сторона
      </div>
      <div slot="backContent">
        <div class="plastic-card__black">
          <div class="card__item">
            <div class="card__img">
              <div class="plastic-card__back">
                <img
                  height="300"
                  src="@/assets/images/business-card/plastic/plastic-bg.svg"
                  alt="rostelecom"
                >
              </div>
              <div class="plastic-card__site-code" v-if="siteCode">
                <img :src="siteCode" :alt="site">
              </div>
            </div>
          </div>
        </div>
      </div>
    </card-frame>

    <div class="visit-card-layout-cnt">
      <canvas
        id="front-card"
        width="460"
        height="300"
        v-show="false"
      />
      <canvas
        id="back-card"
        width="446"
        height="300"
        v-show="false"
      />
    </div>
  </div>
</template>

<script>
import vCardsJS from 'vcards-js';
import QRCode from 'qrcode';
import { COLOR_QR_CODE } from '@/constants/businessCard';
import CardFrame from './card-frame.vue';
import CardMixin from './card-mixin.vue';

export default {
  name: 'PlasticCard',
  props: {
    lang: {
      type: String,
      default: undefined,
    },
    department: {
      type: String,
      default: undefined,
    },
    organization: {
      type: String,
      default: undefined,
    },
    company: {
      type: String,
      default: undefined,
    },
    lastName: {
      type: String,
      default: undefined,
    },
    firstName: {
      type: String,
      default: undefined,
    },
    middleName: {
      type: String,
      default: undefined,
    },
    position: {
      type: String,
      default: undefined,
    },
    mobilePhone: {
      type: String,
      default: undefined,
    },
    workPhone: {
      type: String,
      default: undefined,
    },
    email: {
      type: String,
      default: undefined,
    },
    address: {
      type: String,
      default: undefined,
    },
    site: {
      type: String,
      default: undefined,
    },
    bg: {
      type: String,
      default: undefined,
    },
  },
  components: {
    CardFrame,
  },
  mixins: [CardMixin],
  data() {
    return {
      contactCode: null,
      siteCode: null,
      frontImg: null,
      backImg: null,
      frontId: null,
      backId: null,
    };
  },
  computed: {
    vCardText() {
      const vCard = vCardsJS();

      if (this.lastName) vCard.lastName = this.lastName;
      if (this.firstName) vCard.firstName = this.firstName;
      if (this.middleName) vCard.middleName = this.middleName;

      if (this.company) vCard.organization = this.company;
      if (this.position) vCard.title = this.position;
      if (this.site) vCard.url = this.site;

      if (this.workPhone) vCard.workPhone = this.workPhone;
      if (this.mobilePhone) vCard.cellPhone = this.mobilePhone;
      if (this.email) vCard.workEmail = this.email;

      if (this.address) {
        const addresses = this.parseAddress(this.address);
        vCard.workAddress.street = addresses.address || null;
        vCard.workAddress.city = addresses.city || null;
      }

      return vCard.getFormattedString();
    },
    // isPlastic() {
    //   return this.bg === this.bgTypes.plastic.gray.id;
    // },
  },
  methods: {
    parseAddress(str) {
      let postcode = null;
      const country = 'РФ';
      let city = null;
      let address = null;
      const separatorOffset = 2; // length of country
      const minAddressPartLength = 3; // country, city, local address (street +  houseNumber)

      const returnValue = () => ({
        postcode,
        country,
        city,
        address,
      });

      const countryIndex = str.indexOf(country);

      if (countryIndex === -1) return returnValue();
      const separator = str.charAt(countryIndex + separatorOffset);
      const addressParts = str.split(separator).map((item) => item.trim());

      if (addressParts.length < minAddressPartLength) return returnValue();

      // set postcode
      const postCodeIndex = addressParts.findIndex((item) => /^\d{6}$/.test(item));
      if (postCodeIndex !== -1) {
        postcode = `${addressParts[postCodeIndex]}`;
        addressParts.splice(postCodeIndex, 1);
      }

      // set city and address
      const countryCodeIndex = addressParts.findIndex((item) => item === country);
      if (countryCodeIndex !== -1) {
        city = addressParts[countryCodeIndex + 1] || null;
        const addressIndex = countryCodeIndex + 2;
        address = addressParts[addressIndex]
          ? addressParts.slice(addressIndex).join(`${separator} `)
          : null;
      }

      return returnValue();
    },
    getQRCode(str, width, margin) {
      return QRCode.toDataURL(str, {
        margin,
        width,
        color: {
          dark: COLOR_QR_CODE,
          light: '#0000',
        },
      });
    },
    drawFront() {
      const id = +new Date();
      this.frontId = 0;
      this.frontId += id;

      const canvasFront = document.getElementById('front-card');
      if (!canvasFront) return false;

      const context = canvasFront.getContext('2d');

      const logo = new Image(160, 60);
      logo.setAttribute('crossOrigin', 'anonymous');
      const contactCode = new Image(210, 210);
      // const background = new Image();

      let frontImg1resolve;
      let frontImg2resolve;
      const promiseFront1 = new Promise(
        // eslint-disable-next-line no-return-assign
        (resolve) => (frontImg1resolve = resolve),
      );
      const promiseFront2 = new Promise(
        // eslint-disable-next-line no-return-assign
        (resolve) => (frontImg2resolve = resolve),
      );

      const drawFront = () => {
        if (this.frontId !== id) return;
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvasFront.width, canvasFront.height);

        context.strokeStyle = COLOR_QR_CODE;
        context.lineWidth = 3;
        context.strokeRect(228, 50, 210, 210);
        context.drawImage(contactCode, 228, 50, 210, 210);

        context.drawImage(logo, 25, 20, 160, 41);

        context.fillStyle = 'black';

        // Позиция печати левой части визитки
        const position = {
          XPosition: 25,
          YPosition: 130,
        };

        this.fillMultiLineText(
          context,
          position,
          this.lastName,
          "600 18px/26px 'Rostelecom Basis'",
          18,
          26,
          2,
          200,
        );
        this.fillMultiLineText(
          context,
          position,
          this.firstName,
          "600 18px/26px 'Rostelecom Basis'",
          18,
          26,
          2,
          200,
        );
        this.fillMultiLineText(
          context,
          position,
          this.middleName,
          "600 18px/26px 'Rostelecom Basis'",
          18,
          26,
          3,
          200,
        );
        this.fillMultiLineText(
          context,
          position,
          this.position,
          "400 12px/14px 'Rostelecom Basis'",
          12,
          14,
          1.8,
        );
        this.fillMultiLineText(
          context,
          position,
          this.department,
          "400 12px/14px 'Rostelecom Basis'",
          12,
          14,
          1.8,
        );
        this.fillMultiLineText(
          context,
          position,
          this.organization,
          "400 12px/14px 'Rostelecom Basis'",
          12,
          14,
          1.8,
        );

        const file = this.dataURItoBlob(canvasFront.toDataURL('image/jpeg'));
        file.crossOrigin = 'Anonymous';
        file.lastModifiedDate = new Date();
        file.name = 'layout_1.jpg';
        file.isBlob = true;
        this.frontImg = file;
      };

      logo.addEventListener('load', frontImg1resolve);
      contactCode.addEventListener('load', frontImg2resolve);

      // // eslint-disable-next-line global-require
      // const enLogo = require('@/assets/images/business-card/paper/logo__en.png');

      // eslint-disable-next-line global-require
      const ruLogo = require('@/assets/images/business-card/plastic/plastic-logo__ru.png');

      logo.src = ruLogo;
      contactCode.src = this.contactCode;

      Promise.all([promiseFront1, promiseFront2])
        .then(drawFront)
        .catch((e) => {
          console.error(`drawFront: ${e}`);
        });

      return true;
    },
    drawBack() {
      const id = +new Date();
      this.backId = 0;
      this.backId += id;

      const canvasBack = document.getElementById('back-card');
      if (!canvasBack) return false;

      const context = canvasBack.getContext('2d');

      const bg = new Image(canvasBack.width, canvasBack.height);
      const siteCode = new Image(186, 186);

      let backImg1resolve;
      let backImg2resolve;
      // eslint-disable-next-line no-return-assign
      const promiseBack1 = new Promise((resolve) => (backImg1resolve = resolve));
      // eslint-disable-next-line no-return-assign
      const promiseBack2 = new Promise((resolve) => (backImg2resolve = resolve));

      const drawBack = () => {
        if (this.backId !== id) return;
        context.drawImage(bg, 0, 0, canvasBack.width, canvasBack.height);

        context.strokeStyle = COLOR_QR_CODE;
        context.lineWidth = 3;
        context.strokeRect(40, 62, 186, 186);
        context.drawImage(siteCode, 40, 62, 186, 186);

        const file = this.dataURItoBlob(canvasBack.toDataURL('image/jpeg'));
        file.lastModifiedDate = new Date();
        file.name = 'layout_2.jpg';
        file.isBlob = true;

        this.backImg = file;
      };

      bg.addEventListener('load', backImg1resolve);
      siteCode.addEventListener('load', backImg2resolve);

      // eslint-disable-next-line global-require
      bg.src = require('@/assets/images/business-card/plastic/plastic-bg.png');
      siteCode.src = this.siteCode;

      Promise.all([promiseBack1, promiseBack2])
        .then(drawBack)
        .catch((e) => {
          console.error(`drawBack: ${e}`);
        });

      return true;
    },
    setFiles() {
      this.imgs = [this.frontImg, this.backImg];
    },
  },
  watch: {
    vCardText(val) {
      if (val) {
        this.getQRCode(val, 210, 4).then((code) => {
          this.contactCode = code;
        });
      }
    },
    site(val) {
      if (val) {
        this.getQRCode(val, 186, 2).then((code) => {
          this.siteCode = code;
        });
      }
    },
    siteCode() {
      this.drawBack();
    },
    backImg() {
      this.setFiles();
    },
    frontImg() {
      this.setFiles();
    },
  },
  mounted() {
    if (this.site) {
      this.getQRCode(this.site, 186, 2).then((code) => {
        this.siteCode = code;
      });
    }

    if (this.vCardText) {
      this.getQRCode(this.vCardText, 210, 4).then((code) => {
        this.contactCode = code;
      });
    }

    this.drawFront();
    this.drawBack();
  },
  updated() {
    this.drawFront();
  },
};
</script>

<style lang="scss" scoped>
$gray-light: #ddd;
$plastic-card-gray: #7d95a9;
$black: #333;

.plastic-card__back {
  display: inline-block;
  width: auto;
  img {
    height: 300px;
    border: 1px solid $gray-light;

    @include for-size(phone-portrait-down) {
      height: 209px;
    }
  }
}
.card__img {
  position: relative;
}

.plastic-card__site-code {
  position: absolute;
  top: 52px;
  left: 40px;
  border: 3px solid $plastic-card-gray;

  img {
    width: 100%;
    height: 100%;
  }

  @include for-size(phone-portrait-down) {
    width: 130px;
    height: 130px;
    top: 30px;
  }
}
.plastic-card__contact-code {
  position: absolute;
  top: 44px;
  right: 20px;
  border: 3px solid $plastic-card-gray;

  img {
    width: 100%;
    height: 100%;
  }

  @include for-size(phone-portrait-down) {
    width: 120px;
    height: 120px;
  }
}

.card__item {
  width: 466px;
}
.card__inner {
  height: 300px;
  min-height: 299px;
  padding: 25px 20px 20px 20px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ddd;

  @include for-size(phone-portrait-down) {
    min-height: 209px;
    height: 209px;
    padding: 7px 10px 10px 15px;
  }

}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 180px;
}
.card__logo {
  padding: 0 0 35px;
  line-height: 0;
  img {
    width: 100%;
    max-width: 160px;
    height: auto;
  }
  @include for-size(phone-portrait-down) {
    padding-bottom: 7px;
  }
}
.card__employee {
  padding: 0 0 12px;
  font-family: 'Rostelecom Basis', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: $black;
  @include for-size(phone-portrait-down) {
    font-size: 13px;
    line-height: 16px;
  }
}
.card-name,
.card-patronymic {
  display: inline-block;
  width: auto;
}
.card__position,
.card__branch,
.card__department {
  font-family: 'Rostelecom Basis', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $black;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.plastic-card__front {
  position: relative;
}

.visit-card-layout-cnt {
  width: 100%;
  overflow: hidden;
}
</style>
