<template>
  <div class="business-card">
    <paper-card
      v-if="activeType === types.paper.id"
      :lang="lang"
      :department="department"
      :organization="organization"
      :company="company"
      :last-name="lastName"
      :first-name="firstName"
      :middle-name="middleName"
      :position="position"
      :mobile-phone="mobilePhone"
      :work-phone="workPhone"
      :email="email"
      :address="address"
      :bg="paperBgType"
      :current-checklist="currentChecklist"
      :model="model"
    />

    <plastic-card
      v-else-if="activeType === types.plastic.id"
      :lang="lang"
      :department="department"
      :organization="organization"
      :company="company"
      :last-name="lastName"
      :first-name="firstName"
      :middle-name="middleName"
      :position="position"
      :mobile-phone="mobilePhone"
      :work-phone="workPhone"
      :email="email"
      :address="address"
      :site="siteForPlasticCard"
      :current-checklist="currentChecklist"
      :model="model"
    />
  </div>
</template>

<script>
import {
  isEmpty,
  cloneDeep,
  isObject,
  merge,
} from 'lodash';
import {
  mapGetters,
  mapActions,
  mapState,
} from 'vuex';
import {
  FIELDS,
  FIELD_IDS,
  LANGS,
  TYPES,
  SITE_FOR_PLASTIC_CARD,
  BG_TYPES,
} from '@/constants/businessCard';
import PaperCard from './paper-card.vue';
import PlasticCard from './plastic-card.vue';

export default {
  name: 'BusinessCard',
  props: {
    currentUserInfo: {
      type: Object,
      default: undefined,
    },
    config: {
      type: Object,
      default: undefined,
    },
    value: {
      type: Object,
      default: undefined,
    },
    checklist: {
      type: Array,
      default: undefined,
    },
    beforeCreateTicket: {
      type: Object,
      default: () => ({
        beforeCreateTicketAction: null,
      }),
    },
  },
  components: {
    PaperCard,
    PlasticCard,
  },
  data() {
    return {
      isPattern: false,
      isLoad: false,
      langs: LANGS,
      types: TYPES,
      siteForPlasticCard: SITE_FOR_PLASTIC_CARD,
    };
  },
  computed: {
    ...mapGetters('user', ['selectedOrLoggedInUser']),
    // ...mapState('checklist', ['currentChecklist'/* , 'model' */]),
    ...mapState('businessCard', ['valuesModelRu', 'valuesModelEn']),

    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    currentChecklist() {
      return this.checklist;
    },

    fields() {
      const fields = cloneDeep(FIELDS);
      let ids;

      if (
        this.config
        && this.config.fieldSet
        && FIELD_IDS[this.config.fieldSet]
      ) {
        ids = FIELD_IDS[this.config.fieldSet];
      } else {
        // eslint-disable-next-line prefer-destructuring
        ids = Object.values(FIELD_IDS)[2];
      }

      Object.keys(fields).forEach((fieldName) => {
        fields[fieldName].id = ids[fieldName];
      });

      return fields;
    },

    activeType() {
      const type = this.getModel('type');
      if (type && type.name === this.types.plastic.name) {
        return this.types.plastic.id;
      }

      return this.types.paper.id;
    },
    paperBgType() {
      const employeeType = this.getModel('employeeType');

      if (employeeType && employeeType.name === 'Да') {
        const type = this.getModel('paperBgType2');
        if (type && type.name === BG_TYPES.paper.space.name) {
          return BG_TYPES.paper.space.id;
        }
        return BG_TYPES.paper.gray.id;
      }

      const type = this.getModel('paperBgType1');
      if (type && type.name === BG_TYPES.paper.space.name) {
        return BG_TYPES.paper.space.id;
      }
      if (type && type.name === BG_TYPES.paper.job.name) {
        return BG_TYPES.paper.job.id;
      }
      return BG_TYPES.paper.blue.id;
    },

    lang() {
      const model = this.getModel('lang');
      if (model) return model.name;
      return null;
    },
    department() {
      const model = this.getModel('department');
      if (model) return model;
      return null;
    },
    organization() {
      const model = this.getModel('organization');
      if (model) return model;
      return null;
    },
    company() {
      const model = this.getModel('company');
      if (model) return model.name;
      return null;
    },
    lastName() {
      const model = this.getModel('lastName');
      if (model) return model;
      return null;
    },
    firstName() {
      const model = this.getModel('firstName');
      if (model) return model;
      return null;
    },
    middleName() {
      const model = this.getModel('middleName');
      if (model) return model;
      return null;
    },
    position() {
      const model = this.getModel('position');
      if (model) return model;
      return null;
    },
    mobilePhone() {
      const model = this.getModel('mobilePhone');
      if (model) return model;
      return null;
    },
    workPhone() {
      const model = this.getModel('workPhone');
      if (model) return model;
      return null;
    },
    email() {
      const model = this.getModel('email');
      if (model) return model;
      return null;
    },

    address() {
      const model = this.getModel('address');
      if (model) return model;
      return null;
    },
    fieldsLangs() {
      if (
        this.currentChecklist
        && this.currentChecklist.length
      ) {
        const langId = this.findModelField('lang');
        const field = this.currentChecklist.find(
          (item) => item.id === langId,
        );
        if (field) return field.listValues;
      }
      return [];
    },
  },
  methods: {
    ...mapActions('businessCard', [
      'getBusinessCardTranslation',
      'setValuesModel',
    ]),
    translateString(str) {
      let result = '';
      if (str) {
        for (let i = 0; i < str.length; i += 1) {
          result += this.translateChar(str[i]);
        }
      }
      return result;
    },
    translateChar(ch) {
      switch (ch) {
      case 'А':
        return 'A';
      case 'Б':
        return 'B';
      case 'В':
        return 'V';
      case 'Г':
        return 'G';
      case 'Д':
        return 'D';
      case 'Е':
        return 'E';
      case 'Ё':
        return 'Je';
      case 'Ж':
        return 'Zh';
      case 'З':
        return 'Z';
      case 'И':
        return 'I';
      case 'Й':
        return 'Y';
      case 'К':
        return 'K';
      case 'Л':
        return 'L';
      case 'М':
        return 'M';
      case 'Н':
        return 'N';
      case 'О':
        return 'O';
      case 'П':
        return 'P';
      case 'Р':
        return 'R';
      case 'С':
        return 'S';
      case 'Т':
        return 'T';
      case 'У':
        return 'U';
      case 'Ф':
        return 'F';
      case 'Х':
        return 'Kh';
      case 'Ц':
        return 'C';
      case 'Ч':
        return 'Ch';
      case 'Ш':
        return 'Sh';
      case 'Щ':
        return 'Jsh';
      case 'Ъ':
        return 'Hh';
      case 'Ы':
        return 'Ih';
      case 'Ь':
        return 'Jh';
      case 'Э':
        return 'E';
      case 'Ю':
        return 'Ju';
      case 'Я':
        return 'Ja';
      case 'а':
        return 'a';
      case 'б':
        return 'b';
      case 'в':
        return 'v';
      case 'г':
        return 'g';
      case 'д':
        return 'd';
      case 'е':
        return 'e';
      case 'ё':
        return 'je';
      case 'ж':
        return 'zh';
      case 'з':
        return 'z';
      case 'и':
        return 'i';
      case 'й':
        return 'y';
      case 'к':
        return 'k';
      case 'л':
        return 'l';
      case 'м':
        return 'm';
      case 'н':
        return 'n';
      case 'о':
        return 'o';
      case 'п':
        return 'p';
      case 'р':
        return 'r';
      case 'с':
        return 's';
      case 'т':
        return 't';
      case 'у':
        return 'u';
      case 'ф':
        return 'f';
      case 'х':
        return 'kh';
      case 'ц':
        return 'c';
      case 'ч':
        return 'ch';
      case 'ш':
        return 'sh';
      case 'щ':
        return 'jsh';
      case 'ъ':
        return 'hh';
      case 'ы':
        return 'ih';
      case 'ь':
        return 'jh';
      case 'э':
        return 'e';
      case 'ю':
        return 'ju';
      case 'я':
        return 'ja';
      default:
        return ch.toString();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    findModelField(code) {
      const { id } = this.fields[code];
      return id;
    },
    updateFullModel(model) {
      // eslint-disable-next-line prefer-object-spread
      this.model = Object.assign({}, model);
    },
    getModelScheme({ key, value }) {
      const fields = {};
      this.currentChecklist.some((f) => {
        if (f.id === key) {
          fields[f.id] = value;
        } else if (f.class === 'group') {
          f.childs.some((cf) => {
            if (cf.id === key) {
              fields[f.id] = {};
              fields[f.id][cf.id] = value;
            }
            return !isEmpty(fields);
          });
        }

        return !isEmpty(fields);
      });

      return fields;
    },
    async updateModel({ key, value }) {
      const fields = this.getModelScheme({ key, value });
      // eslint-disable-next-line prefer-object-spread
      const newModel = merge(this.model, fields);
      await this.updateFullModel(newModel);
    },
    getModel(code) {
      // находим  id поля
      const modelCode = this.findModelField(code);
      let model;

      // получаем пустую схему модели со вложенностью
      const fields = this.getModelScheme({ key: modelCode, value: null });
      // получаем нужное значение из модели
      if (!isEmpty(fields)) {
        const first = this.model[Object.keys(fields)[0]];
        if (first && isObject(first)) {
          model = first[modelCode];
        } else {
          model = first;
        }
      }

      // находим поле в чеклисте,
      // ибо если селект, то от значения нам будет грустно
      let field;
      this.currentChecklist.some((f) => {
        if (f.id === modelCode) {
          field = f;
        } else if (f.class === 'group') {
          f.childs.some((cf) => {
            if (cf.id === modelCode) {
              field = cf;
            }

            return !isEmpty(field);
          });
        }

        return !isEmpty(field);
      });

      let value;
      // получаем человекопонятное значение, от которого нам веселей
      if (!isEmpty(field)) {
        if (field.listValues) {
          value = field.listValues.find((v) => v.id === model);
        } else {
          value = model;
        }
      }

      return value || null;
    },
    async getRuModel(isLocal) {
      const localModel = {};
      const updateModel = async (model) => {
        if (isLocal) {
          localModel[model.key] = model.value;
        } else {
          await this.updateModel(model);
        }
      };

      // set user info
      const fieldsKeys = Object.keys(this.fields);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < fieldsKeys.length; i++) {
        const fieldName = fieldsKeys[i];
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick();
        const field = this.fields[fieldName];
        if (field.userField) {
          const fieldValue = this.selectedOrLoggedInUser[field.userField];
          if (fieldValue) {
            const fieldKey = this.findModelField(fieldName);
            // eslint-disable-next-line no-await-in-loop
            await updateModel({
              key: fieldKey,
              value: `${fieldValue}`,
            });
          }
        }
      }

      // set company
      const companyKey = this.findModelField('company');
      const companyField = this.currentChecklist.find(
        (field) => field.id === companyKey,
      );
      if (
        companyField
        && companyField.listValues
        && companyField.listValues.length
      ) {
        const companyValue = companyField.listValues[0];
        updateModel({
          key: companyKey,
          value: companyValue.id,
        });
      }

      // set organization (группирующий узел)
      const organizationKey = this.findModelField('organization');
      const organizationFullValue = this.selectedOrLoggedInUser.corpStructure;
      const organizationValues = organizationFullValue.split(' | ');
      const organizationValue = organizationValues && organizationValues.length > 1
        ? organizationValues[1]
        : null;
      if (organizationValue) {
        updateModel({
          key: organizationKey,
          value: `${organizationValue}`,
        });
      }

      // set address (location)
      const addressKey = this.findModelField('address');
      const addressFullValue = this.selectedOrLoggedInUser.location;
      const addressValue = addressFullValue
        ? addressFullValue.replace('Российская Федерация', 'РФ')
        : null;
      if (addressValue) {
        updateModel({
          key: addressKey,
          value: `${addressValue}`,
        });
      }

      // set user names
      const names = this.selectedOrLoggedInUser.fullName.split(' ');
      if (names.length <= 3 && names.length >= 2) {
        const fieldKey0 = this.findModelField('lastName');
        const fieldValue0 = names[0];
        await this.$nextTick();
        await updateModel({
          key: fieldKey0,
          value: `${fieldValue0}`,
        });

        const fieldKey1 = this.findModelField('firstName');
        // eslint-disable-next-line prefer-destructuring
        const fieldValue1 = names[1];
        await this.$nextTick();
        await updateModel({
          key: fieldKey1,
          value: `${fieldValue1}`,
        });

        if (names[2]) {
          const fieldKey2 = this.findModelField('middleName');
          // eslint-disable-next-line prefer-destructuring
          const fieldValue2 = names[2];
          await this.$nextTick();
          await updateModel({
            key: fieldKey2,
            value: `${fieldValue2}`,
          });
        }
      }

      if (isLocal) {
        this.setValuesModel({ lang: this.langs.RU.code, value: localModel });
      }
    },
    async getEnModel() {
      const enModel = {};

      // get user info
      const userLocation = this.selectedOrLoggedInUser
        ? this.selectedOrLoggedInUser.location.replace('Российская Федерация', 'РФ')
        : '';
      const appointmentName = this.selectedOrLoggedInUser?.appointmentName || '';

      const enModelRaw = await this.getBusinessCardTranslation({
        position: appointmentName,
        address: userLocation,
      });
      Object.keys(enModelRaw).forEach((key) => {
        const modelField = this.findModelField(key);
        if (modelField) {
          enModel[modelField] = enModelRaw[key];
        }
      });

      // set user names
      const names = this.selectedOrLoggedInUser.fullName.split(' ');
      if (names.length <= 3 && names.length >= 2) {
        let modelField = this.findModelField('lastName');
        // получаем пустую схему модели со вложенностью
        const fields = this.getModelScheme({ key: modelField, value: null });
        // добавляем в модель группу для этих данных
        const fullNameGroupId = Object.keys(fields)[0];
        enModel[fullNameGroupId] = {};
        if (modelField) {
          enModel[fullNameGroupId][modelField] = this.translateString(names[0]);
        }

        modelField = this.findModelField('firstName');
        if (modelField) {
          enModel[fullNameGroupId][modelField] = this.translateString(names[1]);
        }

        if (names[2]) {
          modelField = this.findModelField('middleName');
          if (modelField) {
            enModel[fullNameGroupId][modelField] = this.translateString(names[2]);
          }
        }
      }

      this.setValuesModel({ lang: this.langs.EN.code, value: enModel });
    },
    async initClear() {
      if (isEmpty(this.selectedOrLoggedInUser) || isEmpty(this.currentChecklist)) return;

      const langCode = this.findModelField('lang');
      const defaultLang = this.fieldsLangs.find(
        (item) => item.name === this.langs.RU.text,
      );
      this.updateModel({
        key: langCode,
        value: defaultLang.id,
      });
      await this.$nextTick();
      this.getRuModel();
      this.getEnModel();
    },
    init() {
      if (!this.isPattern) {
        this.initClear();
      }
    },
  },
  watch: {
    lang(val, oldVal) {
      if (val) {
        if (oldVal) {
          const langCode = this.findModelField('lang');
          let code; // for setting old valuesModel[lang]
          let newModel;

          if (val === this.langs.RU.text) {
            // eslint-disable-next-line prefer-destructuring
            code = `${this.langs.EN.code}`;
            newModel = JSON.parse(JSON.stringify(this.valuesModelRu));
          } else {
            // eslint-disable-next-line prefer-destructuring
            code = `${this.langs.RU.code}`;
            newModel = JSON.parse(JSON.stringify(this.valuesModelEn));
          }

          const oldModel = JSON.parse(JSON.stringify(this.model));
          const oldLang = this.fieldsLangs.find((item) => item.name === oldVal);
          oldModel[langCode] = oldLang.id;
          this.setValuesModel({
            lang: code,
            value: oldModel,
          });

          const newLang = this.fieldsLangs.find((item) => item.name === val);
          newModel[langCode] = newLang.id;
          // eslint-disable-next-line prefer-object-spread
          this.updateFullModel(newModel);
        } else {
          this.setValuesModel({
            lang: val,
            value: this.model,
          });
          if (val === this.langs.EN.text) {
            this.getRuModel(true);
          } else {
            this.getEnModel();
          }
        }
      }
    },
    currentChecklist(val) {
      if (val) {
        this.init();
      }
    },
    selectedOrLoggedInUser(val) {
      if (val) {
        this.init();
      }
    },
  },
  async mounted() {
    this.isPattern = this.$route.query && !!this.$route.query.templateId;
    await this.$nextTick();
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.business-card {
  margin-top: 40px;
}
</style>
